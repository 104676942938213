import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import styled from 'styled-components';

const BlackFridayBlog = ({ className }) => (
  <Layout className={className}>
    <SEO title="Blog | Black Friday" />
    <div className="content">
      <header>
        <h3>Black Friday</h3>
        <p>
          the trust proof way
        </p>
      </header>
      The day is finally here. A discount this Friday is what everyone is hoping for. But some, after the event, would wish they paid the full price because if you get scammed on a discount, you've paid a far more expensive price than the original without a discount. As foongit, we’re here to give you the 411 around the various methods of payments and how to protect yourself this Friday from scammers and fraudsters.
      <br />
      <br />
      First things first, the most important questions to ask yourself when taking up the so called "up for grabs" Black Friday and Cyber Monday deals is: How am I choosing to complete the transaction and am protected in the process should anyone of these scenarios happen :
      <ul>
        <li>I did not receive my goods</li>
        <li>Should I realize that the seller is a fly by night</li>
        <li>The goods I receive are counterfeit</li>
        <li>The goods aren't what I ordered</li>
      </ul>
      The burning question is: Is there anyway for me to make a payment and have a guarantee that I will receive my funds should anything go to hell?
      <br />
      <br />
      When paying for your goods this coming black Friday via EFT and happen to be scammed by an individual, you will not be able to recover those funds. Banks don't have authority to debit funds from peoples accounts just because you tell them you have been scammed. Asisebenze kanjalo.
      <br />
      <br />
      However, If you pay online with your card, and the merchant or online shop is a scammer, you may recover the funds through your  bank if you complete a form to dispute the transaction. This process can take up to 45 days as your bank still needs to contact the merchant to verify that goods weren't delivered. You will have to provide the bank with additional information before they can reverse the transaction. 
      <br />
      <br />
      What about a Cash Deposit at an ATM? That’s  even worse, usually the scammer is already waiting to withdraw all the funds. When you make such a deposit, you take all the risk for that transaction and your bank will not be able to bail you out. You’d walk away from the ATM as a freshly scammed. 
      <br />
      <br />
      Using alternative transfer methods like an eWallet by FNB, Cash Send by ABSA, or Send Voucher by Standard Bank, there exists a possibility to get your money back, only if you are quick enough to realise the scam and if the receiver has not withdrawn the funds yet. 
      <br />
      <br />
      The above summarizes the most commonly used ways of payments during this season to complete transactions, and all they have in common, is the inability to protect you or assist you in recovering your hard earned money. 
      <br />
      <br />
      So, if you happen to be buying from OLX, Gumtree, an Instagram Boutique page , Facebook Marketplace or any other classified platform where buyers and sellers meet. We propose you utilize Foongit. Yes, Foongit. This is your payment solution that will protect your money when you pay someone who is selling you goods on any of these platforms. Your money will be stored in a secured Vault and the seller will be notified once the payment has been made. They will not have access to the funds until you confirm that you have received your goods. This solution protects both the buyer and the seller as it provides two options. To receive Money, when selling goods and want to ensure you are not scammed with fake proof of payments, Or you are Sending Money to buy goods and take advantage of a Black Friday discount, Foongit has got you covered by giving a guarantee and ensuring they your funds are safe from as little as R4.99.
      <br />
      <br />
      <span className="bold">So, download the Foongit App today and ensure you are protected when transacting this Black Friday and every other day.</span>
      <br />
      <br />
      Just foongit! 
    </div>
  </Layout>
);

export default styled(BlackFridayBlog)`
  & .content {
    header {
      background-color: var(--color-dark-grey);
      text-align: center;
      padding: 10px;
      margin-bottom: 10px;

      h3 {
        color: var(--color-primary);
        font-size: 2rem;
      }
      p {
        flex: 1;
        font-size: 0.9rem;
        color: white;
        font-weight: 700;
      }
    }

    padding: 0 15%;

    li, .bold {
      font-weight: 700;
    }
  }
`;
